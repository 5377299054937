import * as React from "react";

import Layout from "../../components/Layout";
import EventsRoll from "../../components/EventsRoll";
import heroImage from "../../img/jvictoriacollege-ca-hero-image.jpg";

export default class EventsIndexPage extends React.Component {
  render() {
    return (
      <Layout
        pageTitle="Events"
        pageDescription="Index page of all upcoming and past events of JVC OSA Canada."
        pageSlug="/events"
      >
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('${heroImage}')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
              backgroundColor: "#f40",
              color: "white",
              padding: "1rem",
            }}
          >
            Latest Events
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <EventsRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
